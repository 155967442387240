<template>
  <div v-if="currentUser">
    <mainMenu />

    <v-app-bar
      app
      color="gray"
      dark
      flat
    >
      <v-app-bar-nav-icon @click.stop="drawerClick" />
      <v-toolbar-title>Gestion des DPC</v-toolbar-title>
      <v-spacer />
      {{ currentUser.name }}
      <router-link
        to="/deconnexion"
        class="ml-4"
      >
        <v-icon>mdi-power</v-icon>
      </router-link>
    </v-app-bar>

    <v-main>
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col class="text-center">
            <router-view />
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-footer
      color="gray"
      dark
      flat
      app
      class="pa-2"
    >
      <span class="white--text mx-auto">&copy; {{ new Date().getFullYear() }} Agence CCC</span>
    </v-footer>
  </div>
</template>

<script>
import mainMenu from "../mainMenu/mainMenu.vue"

export default{
  methods:{
    drawerClick(){
      this.$store.dispatch('mainMenu/toggle');
    }
  },
  beforeMount(){
    this.$store.dispatch('auth/clearErrors');
  },
  mounted(){
    this.$store.dispatch('scrollToTop');
  },
  components:{
    mainMenu
  }
}

</script>
