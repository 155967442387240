<template>
  <v-navigation-drawer
    :value="drawer"
    app
  >
    <v-list dense>
      <v-list-item
        link
        to="/dashboard"
      >
        <v-list-item-action>
          <v-icon>mdi-view-dashboard-variant-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item 
        link
        to="/sessions"
        v-if="can('admin_sessions_access')"
      >
        <v-list-item-action>
          <v-icon>mdi-school-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Sessions DPC</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item 
        link
        to="/inscriptions"
        v-if="can('admin_dpc_inscriptions_access')"
      >
        <v-list-item-action>
          <v-icon>mdi-account-question-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Inscriptions en attente</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      
      <v-list-item 
        link
        to="/dpc"
        v-if="can('admin_dpc_access')"
      >
        <v-list-item-action>
          <v-icon>mdi-book-open-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>DPC</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      
      <v-list-item
        link
        to="/utilisateurs"
        v-if="can('admin_users_access')"
      >
        <v-list-item-action>
          <v-icon>mdi-account-box-multiple-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Utilisateurs</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      
      <v-list-item
        link
        to="/permissions"
        v-if="can('admin_roles_access')"
      >
        <v-list-item-action>
          <v-icon>mdi-account-lock-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Groupes / Permissions</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      
      <v-list-item 
        link
        to="/deconnexion" 
      >
        <v-list-item-action>
          <v-icon>mdi-power</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Déconnexion</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>

export default{
  computed : {
    drawer(){
      return this.$store.getters['mainMenu/isOpen'];
    },
  },
}

</script>